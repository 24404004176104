import { GridCellKind } from '@wf-mfe/spectrum-data-grid'

import { CanvasColumn, LANGUAGES, LANGUAGE_CODES } from '../types'

// TODO: remove this file as soon as Localization with Glist is enabled on prod
export const columns: Array<CanvasColumn> = [
  {
    title: LANGUAGES.English,
    id: LANGUAGE_CODES.English,
    cellKind: GridCellKind.Text,
    isRequired: true,
  },
  {
    title: LANGUAGES.Spanish,
    id: LANGUAGE_CODES.Spanish,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.French,
    id: LANGUAGE_CODES.French,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.German,
    id: LANGUAGE_CODES.German,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Italic,
    id: LANGUAGE_CODES.Italic,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Portuguese,
    id: LANGUAGE_CODES.Portuguese,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Chinese,
    id: LANGUAGE_CODES.Chinese,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Korean,
    id: LANGUAGE_CODES.Korean,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Japan,
    id: LANGUAGE_CODES.Japan,
    cellKind: GridCellKind.Text,
  },
  {
    title: LANGUAGES.Taiwanese,
    id: LANGUAGE_CODES.Taiwanese,
    cellKind: GridCellKind.Text,
  },
]

export const TOAST_TIMEOUT = 1000

export const USER_LOCALIZATIONS_LIMIT = 10000
