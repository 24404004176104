import React, { memo, useCallback, useEffect, useState } from 'react'
import { ToastQueue } from '@react-spectrum/toast'
import { wfetch } from '@wf-mfe/api'
import { useCurrentCustomer } from '@wf-mfe/auth'
import { ICommand, ITableRow, IRow, List, useDeleteCommand } from '@wf-mfe/glist'

import { fields, USER_LOCALIZATIONS_LIMIT, TOAST_TIMEOUT } from '../../constants'
import { IItem } from '../../types'
import { generateRowsFromTranslations } from '../../utils'

const LocalizationList: React.FC = () => {
  const [rows, setRows] = useState<Array<IRow>>([])

  const currentCustomer = useCurrentCustomer()
  const { ID: customerID } = currentCustomer

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await wfetch(`/localizer/api/messages?limit=${USER_LOCALIZATIONS_LIMIT}&page=1`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'wf-customerid': customerID,
          },
          body: JSON.stringify({ keys: [] }),
        })

        const responseData = await response.json()
        const existingMessageKeys = responseData.results

        const rowsFromTranslations = generateRowsFromTranslations(fields, existingMessageKeys)

        setRows(rowsFromTranslations)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [customerID])

  const handleDeleteRow = useCallback(
    async (selectedRows: Array<ITableRow>) => {
      const deletedKeys = selectedRows.map((row) => row.id)
      try {
        return await wfetch('/localizer/api/messages', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'wf-customerid': customerID,
          },
          body: JSON.stringify({ keys: deletedKeys }),
        })
      } catch (error) {
        return error
      }
    },
    [customerID]
  )

  const upsertRecords = useCallback(
    async (records) =>
      wfetch('/localizer/api/messages/bulk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'wf-customerid': customerID,
        },
        body: JSON.stringify(records),
      }),
    [customerID]
  )

  const getPayload = useCallback((editedRow, isNew?: boolean) => {
    const { data: rowData } = editedRow.row

    const translations: Record<string, string> = {}

    for (const [id, translation] of Object.entries(rowData)) {
      if (editedRow.changes[id]) {
        translations[id] = editedRow.changes[id].newValue.value
      } else {
        // @ts-expect-error remove ignore when editedRow type is available
        translations[id] = translation.data.value
      }
    }
    const isEnglishKeyChanged = Boolean(editedRow.changes.en)

    // TODO this is a temporary solution untill edited data will be updated in the grid state
    let currentKey = ''
    if (!isNew) {
      if (isEnglishKeyChanged) {
        currentKey = editedRow.row.id
      } else currentKey = editedRow.row.data.en.data.value
    }

    return {
      key: currentKey,
      translations,
    }
  }, [])

  const onRowAdded = useCallback(
    async (newRow) => {
      try {
        const payload = getPayload(newRow, true)
        await upsertRecords([payload])
        return generateRowsFromTranslations(fields, [payload as IItem])[0]
      } catch (err: any) {
        const error = err
        const message = error?.errorData.title || error.message
        if (message) ToastQueue.negative(message, { timeout: TOAST_TIMEOUT })

        throw error
      }
    },
    [upsertRecords, getPayload]
  )

  const onDataEdited = useCallback(
    async (editPayload) => {
      try {
        const editedRows = Object.keys(editPayload).map((key) => editPayload[key])
        const payload: any = []

        editedRows.forEach((editedRow) => {
          payload.push(getPayload(editedRow))
        })
        await upsertRecords(payload)
      } catch (err: any) {
        const error = err
        const message = error?.errorData.title || error.message
        if (message) ToastQueue.negative(message, { timeout: TOAST_TIMEOUT })

        throw error
      }
    },
    [upsertRecords, getPayload]
  )

  const isDeleteActive = useCallback(() => {
    return true
  }, [])

  const deleteHandler = useCallback(
    async (selection: Array<ITableRow>, _: () => void) => {
      await handleDeleteRow(selection)
    },
    [handleDeleteRow]
  )

  const deleteCommand = useDeleteCommand(isDeleteActive, deleteHandler)

  const commands: Array<ICommand> = [deleteCommand]

  return (
    <List
      viewKey="localization"
      fields={fields}
      rows={rows}
      commands={commands}
      rowsCount={rows.length}
      onRowsEdited={onDataEdited}
      onRowAdded={onRowAdded}
      selectionMode="multi"
      showRowIndices={false}
      freezeColumns={1}
    />
  )
}

const MemoizedLocalizationList = memo(LocalizationList)

export { MemoizedLocalizationList as LocalizationList }
